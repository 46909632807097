const { HUGO_APP_URL } = process.env;

export default {
    HUGO_FACEBOOK: `https://www.facebook.com/withhugo/`,
    HUGO_FAQ: `https://withhugo.zendesk.com/hc/en-us`,
    HUGO_INSTAGRAM: `https://www.instagram.com/with.hugo/`,
    HUGO_JOBS: `https://arc.dev/remote-jobs/details/full-stack-senior-engineer-remote-gr88jl066j`,
    HUGO_TRUSTPILOT: `https://www.trustpilot.com/review/www.withhugo.com`,
    HUGO_TWITTER: `https://twitter.com/with_Hugo/`,
    MAIL_SUPPORT: `support@withhugo.com`,
    // app links
    HUGO_APP: `${HUGO_APP_URL}/`,
    HUGO_REFILL: `${HUGO_APP_URL}/`,
    JOIN_HUGO: `${HUGO_APP_URL}/join`,
    QUOTE_FLOW: `${HUGO_APP_URL}/quote`,
    HELPDESK: `${HUGO_APP_URL}/helpdesk`,
};
